import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { retrieveLogoUrl, uploadBrandedPortalImages, uploadToS3 } from 'src/features/config/configApi';
import { getUserInfo } from 'src/features/user/userState';
import { useAppSelector } from 'src/hooks/hooks';

const BlogsQuill = ({ debouncedHandleChange, editorValue, register, errors, name, control }) => {
    const quillRef = useRef(null);
    const userInfo = useAppSelector(getUserInfo);

    const [editor, setEditor] = useState(null);
    const [quillLoaded, setQuillLoaded] = useState(false);

    useEffect(() => {
        if (quillRef.current && !quillLoaded) {
            const quillInstance = quillRef.current.getEditor(); 

            quillInstance.getModule('toolbar').addHandler('image', imageHandler);

            setEditor(quillInstance);
            setQuillLoaded(true);
        }
    }, [quillLoaded]);

    // Image handler for uploading to S3 and inserting into the editor
    const imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async (e) => {
            const file = e.target.files[0];

            if (file) {
                try {
                    const fileURL = URL.createObjectURL(file);

                    const fileType = file.type;
                    const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/svg+xml"];

                    if (!validImageTypes.includes(fileType)) {
                        alert("File format must be JPG, PNG, or SVG.");
                        URL.revokeObjectURL(fileURL);
                        return;
                    }

                    const fileExtension = file.name.split(".").pop();
                    const fileBody = {
                        content_type: fileType,
                        file_extension: fileExtension,
                        upload_task: "marketing_blogs",
                        file_name: file.name.split(".")[0]
                    };
                    const uploadResponse = await uploadBrandedPortalImages(fileBody, userInfo);
                    if (uploadResponse.statusCode === 200) {
                        const headers = {
                            "Content-Type": fileType,
                            "Content-Length": file.size.toString(),
                            "x-amz-acl": "public-read",
                        };
                        await uploadToS3(uploadResponse.data.pre_signed_url, file, headers);

                        const finalURL = `${retrieveLogoUrl}/${uploadResponse.data.file_path}`;
                        const editor = quillRef.current.getEditor();
                        const range = editor.getSelection();
                        console.log(finalURL,"finalURL")
                        editor.insertEmbed(range.index, 'image', finalURL);
                        
                        // After inserting the image, make it resizable
                        makeResizable(finalURL);
                    }
                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            }
        };
    };

    // Function to enable resizing of inserted images
    const makeResizable = (imageUrl) => {
        const quill = quillRef.current.getEditor();
        const images = quill.root.querySelectorAll('img');
        
        images.forEach(image => {
            if (image.src === imageUrl) {
                image.style.resize = 'both';
                image.style.overflow = 'hidden';
                image.style.maxWidth = '100%';
                image.style.cursor = 'se-resize'; // Add a resize cursor for drag

                // Create a resize handle
                const resizeHandle = document.createElement('div');
                resizeHandle.style.width = '10px';
                resizeHandle.style.height = '10px';
                resizeHandle.style.backgroundColor = 'rgba(0,0,0,0.5)';
                resizeHandle.style.position = 'absolute';
                resizeHandle.style.bottom = '0px';
                resizeHandle.style.right = '0px';
                resizeHandle.style.cursor = 'se-resize';
                image.style.position = 'relative';
                image.parentElement.appendChild(resizeHandle);

                // Add mouse events for resizing
                resizeHandle.addEventListener('mousedown', (e) => {
                    e.preventDefault();
                    const startX = e.clientX;
                    const startY = e.clientY;
                    const startWidth = image.offsetWidth;
                    const startHeight = image.offsetHeight;

                    const onMouseMove = (moveEvent) => {
                        const deltaX = moveEvent.clientX - startX;
                        const deltaY = moveEvent.clientY - startY;
                        image.style.width = `${startWidth + deltaX}px`;
                        image.style.height = `${startHeight + deltaY}px`;
                    };

                    const onMouseUp = () => {
                        document.removeEventListener('mousemove', onMouseMove);
                        document.removeEventListener('mouseup', onMouseUp);
                    };

                    document.addEventListener('mousemove', onMouseMove);
                    document.addEventListener('mouseup', onMouseUp);
                });
            }
        });
    };

    const modules = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: "check" }, { indent: '-1' }, { indent: '+1' }],
                [{ direction: "rtl" }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                [{ size: ["small", false, "large", "huge"] }],
                ['link', 'image'],
                ['clean'],
            ],
        },
    };

    const validateContent = (value) => {
        const isEmpty = !value || value === "<p><br></p>" || value.trim() === "";
        return isEmpty ? "Content is required" : true;
    };

    return (
        <div>
            <Box>
                <label className={"formLabel"}>Blog Content*</label>
                <Controller
                    name={name}
                    control={control}
                    rules={{ validate: validateContent }}
                    render={({ field }) => (
                        <ReactQuill
                            {...field}
                            modules={modules}
                            theme="snow"
                            style={{ marginTop: "10px" }}
                            ref={quillRef}
                        />
                    )}
                />
            </Box>
            <span className={"text-error"}>{errors?.[name] && errors?.[name]?.message}</span>
        </div>
    );
}

export default BlogsQuill;
