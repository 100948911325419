import axios from "axios";
import { extractData } from "src/dataFetcher/extractData";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendTenantUrl } from "src/utils/backendUrls";

export const createBlogsApi = async (data) => {
	const res = await axios.post(`https://qmcundq0fl.execute-api.us-east-2.amazonaws.com/v1/tenant/blogs/create_blogs`, data);
	return res;
    // const res = await postData(`${backendTenantUrl}tenant/blogs/create_blogs`, null, data, null);
    // return JSON.parse(extractData({ data: res }));

}

export const updateBlogsApi = async (data, id) => {
    // const res = await putData(`${backendTenantUrl}tenant/blogs/update_blogs/${id}`, null, data, null);
    // return JSON.parse(extractData(res));
    const res = await axios.put(`https://qmcundq0fl.execute-api.us-east-2.amazonaws.com/v1/tenant/blogs/update_blogs/${id}`, data);
	return res;

}

export const getBlogsByIdApi = async (id) => {
    const res = await getData(`${backendTenantUrl}tenant/blogs/blogs_list/${id}`, null, null, null);
    return res
}

export const deleteBlogsApi = async (data) => {
    const res = await postData(`${backendTenantUrl}tenant/blogs/delete_blogs`, null, data, null);
    return JSON.parse(extractData({ data: res }));
}

export async function GetBlogList(userInfo) {
	const token = userInfo?.accessToken;
  const headers={}
	try {
	  const result = await getData(
		`${backendTenantUrl}tenant/blogs/blogs_list`,
		null,
		headers,
		token
	  );
	  if (!result) {
		throw new Error("Empty response from API.");
	  }
	  return result;
	} catch (error) {
	  console.error("Error fetching blog info:", error);
	  throw error;
	}
  }
  export async function UpdateBlogList(data, userInfo) {
	const url = backendTenantUrl;
	const headers={}
	const token = userInfo?.accessToken;
	const result = await putData(
	  `${url}tenant/blogs/update_blogs_order`,
	  headers,
	  data,
	  token
	);
	return result;
}
  
  export async function deleteBlogList(data,userInfo) {
	const token = userInfo?.accessToken;
	const url = backendTenantUrl;
	const header={}
	const result= await postData(
		`${url}tenant/blogs/delete_blogs`,
		header,
		data,
		token
	);
	return JSON.parse(extractData({ data: result }));
}
  export async function toggleArchiveAPI(data,userInfo) {
	
	const token = userInfo?.accessToken;
	const url = backendTenantUrl;
	const header={}
	const result= await putData(
		`${url}tenant/blogs/update_blogs/${userInfo?.blog_id}`,
		header,
		data,
		token
	);
	return result;
}