import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { Box, Button, Grid, Typography } from '@mui/material';
import "./blogs.scss"
import BlogsQuill from 'src/components/BlogsComponent/BlogsQuill/BlogsQuill';
import BlogsInput from 'src/components/BlogsComponent/BlogsInput';
import { Helmet } from 'react-helmet-async';
import AccountPopover from 'src/layouts/dashboard/header/AccountPopover';
import { useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BlogsImageUploader from './BlogsImageUploader';
import palette from 'src/theme/palette';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { createBlogsApi, deleteBlogsApi, getBlogsByIdApi, updateBlogsApi } from 'src/features/blogs/blogsapi';
import ModalComponent from 'src/components/modal/ModalComponent';
import { useDispatch } from 'react-redux';
import { decrementAwaitingApiCounter, incrementAwaitingApiCounter } from "../../../src/features/ui/uiState";


const CreateBlogs = () => {
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm()
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const blog_id = queryParams.get('blog_id');
  const [isPopupOpen, setIsPopUpOpen] = useState(false)
  const [singleBlogData, setSingleBlogData] = useState()
  const [bannerImage, setBannerImage] = useState(null);
  const [footerImage, setFooterImage] = useState(null);
  const [imgError, setImgError] = useState({
    bannerImage: false,
    footerImage: false
  })

  const popUpMessages = {
    "draft": {
      popupMessage: "Are you sure you want to save this blog as a draft?",
      subText: "It will not be visible to readers until published",
      status: "draft",
      buttonText: "Yes, Save as draft"
    },
    "archive": {
      popupMessage: "Are you sure you want to archive this blog?",
      subText: "Archived blogs will no longer be visible to readers",
      status: "archive",
      buttonText: "Yes, Archive"
    },
    "unarchive": {
      popupMessage: "Are you sure you want to unarchive this blog?",
      subText: "UnArchived blogs will be visible to readers",
      status: "unarchive",
      buttonText: "Yes, Unarchive"
    },
    "delete": {
      popupMessage: "Are you sure you want to delete this blog?",
      subText: "This blog will be permanently deleted and cannot be restored",
      status: "delete",
      buttonText: "yes, Delete"
    },
    "published": {
      popupMessage: "Are you sure you want to publish this blog?",
      subText: "Once published, it will be visible to all readers.",
      status: "published",
      buttonText: "Yes, Publish"
    }
  }
  const dispatch = useDispatch()
  const [popupMessage, setPopupMessage] = useState()
  const [submitPayload, setSubmitPayload] = useState()

  useEffect(() => {
    if (blog_id !== null) getBlogsById()
  }, [])


  const getBlogsById = async () => {
    try {
      dispatch(incrementAwaitingApiCounter());

      let response = await getBlogsByIdApi(blog_id);
      if (response.statusCode == 200) {
        reset({ ...response?.data })
        setSingleBlogData(response?.data)
        setFooterImage(response?.data?.footerImage ? { fileName: response.data.footerImage?.split('/')?.pop() } : null)
        setBannerImage(response?.data?.bannerImage ? { fileName: response.data.bannerImage?.split('/')?.pop() } : null)
        dispatch(decrementAwaitingApiCounter());
      }
    } catch (err) {
      dispatch(decrementAwaitingApiCounter());
    }
  }


  const onSubmit = (data, status) => {
    if (bannerImage == null) {
      setImgError({
        ...imgError,
        bannerImage: true
      })
      return
    }
    
    let payload = {
      ...data,
      status,
      bannerImage: bannerImage !== null ? bannerImage.path : null,
      footerImage: footerImage !== null ? footerImage.path : null
    }
    // if (footerImage == null) {
    //   setImgError({
    //     ...imgError,
    //     footerImage: true
    //   })
    //   return
    // }

    // if (bannerImage !== null) {
    //   payload = {
    //     ...payload,
    //     bannerImage: bannerImage.path
    //   }
    // }
    // if (footerImage !== null) {
    //   payload = {
    //     ...payload,
    //     footerImage: footerImage.path
    //   }
    // }

    setSubmitPayload(payload)
    setIsPopUpOpen(true)
    setPopupMessage(popUpMessages[status])
  }

  const handleApiCall = async (status) => {
    try {
      if (blog_id == null) {
        let response = await createBlogsApi(submitPayload)
        if (response?.statusCode == 200) {
          setIsPopUpOpen(false);
          navigate("/dashboard/blogs")
          // setPopupMessage({popupMessage:"Successfully created"})
        }
      } else if (blog_id !== null && (status == "draft" || status == "published" || status == "archive" || status == "unarchive")) {
        delete submitPayload.order;
        delete submitPayload.updated_at;
        delete submitPayload._id;

        let response = await updateBlogsApi(submitPayload, blog_id)
        if (response?.statusCode == 200) {
          setIsPopUpOpen(false)
          getBlogsById()
          navigate("/dashboard/blogs")
          // setPopupMessage({popupMessage:"Updated Successfully"})
        }
      } else if (status == "delete" && blog_id !== null) {
        let response = await deleteBlogsApi({ blog_id: [blog_id] })
        if (response?.statusCode == 200) {
          setIsPopUpOpen(false)
          navigate("/dashboard/blogs")
        }
      }
    } catch (err) {
      setIsPopUpOpen(false)
    }
  }

  return (
    <div>
      <Helmet>
        <title> Blogs | BeyondCarts CMS </title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #c4c4c4",
          paddingBottom: 2,
          marginTop: "20px",
        }}
      >
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <ArrowBackIcon sx={{ fill: "#000", fontSize: "35px", cursor: "pointer" }} onClick={() => navigate("/dashboard/blogs")} />
          <Typography variant="h4" sx={{ color: "#000" }}>
            {blog_id !== null ? "Edit blogs" : "Add blogs"}
          </Typography>
        </Box>
        <AccountPopover />
      </Box>

      {blog_id !== null &&
        <Box display={"flex"} justifyContent={"flex-end"} gap={2} alignItems={"center"}>
          <Box mt={3}>
            <span
              style={{
                height: "20px",
                width: "40px",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "14px",
                fontWeight: 700,
                background: singleBlogData?.status == "draft" && "#F8E6C3" || singleBlogData?.status == "published" && "#C9FBC4" || singleBlogData?.status == "archive" && "#FEC4C4",
                color: singleBlogData?.status == "draft" && "#DC9510" || singleBlogData?.status == "published" && "#1D9210" || singleBlogData?.status == "archive" && "#E50909"
              }}
            >{singleBlogData?.status == "draft" ? "Draft" : singleBlogData?.status == "published" ? "Published" : "Archived"}</span>
          </Box>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{
              marginTop: 3,
              boxShadow: "none",
            }}
            onClick={handleSubmit((value) => onSubmit(value, "draft"))}
          >
            Save as draft
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{
              marginTop: 3,
              boxShadow: "none",
              backgroundColor:
                palette.secondary.main,
            }}
            onClick={handleSubmit((value) => onSubmit(value, "published"))}
          >
            Publish
          </Button>
        </Box>
      }

      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BlogsInput
              type={"text"}
              label={"Blog title *"}
              name={"blogTitle"}
              charactersAllowed={250}
              register={register('blogTitle', {
                required: 'Title is required',
                maxLength: {
                  value: 250,
                  message: 'Title must be less than 250 characters'
                }
              })}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <BlogsInput
              type={"text"}
              label={"Meta keywords *"}
              charactersAllowed={250}
              name={"metaKeyword"}
              register={register('metaKeyword', {
                required: 'Meta keywords is required',
                maxLength: {
                  value: 250,
                  message: 'Meta keywords must be less than 250 characters'
                }
              })}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container spacing={3}>
          <Grid item xs={6} mt={3}>
            <label className='formLabel'>Banner image *</label>
            <BlogsImageUploader
              required={imgError.bannerImage && bannerImage == null}
              setValue={(val) => setBannerImage(val)}
              value={bannerImage}
              handleImageDelete={(e) => { setBannerImage(null) }}
            />
          </Grid>
          <Grid item xs={6} mt={3}>
            <label className='formLabel'>Footer banner image (Optional)</label>
            <BlogsImageUploader
              setValue={(val) => setFooterImage(val)}
              required={imgError.footerImage}
              value={footerImage}
              handleImageDelete={(e) => { setFooterImage(null) }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <BlogsInput
          type={"textArea"}
          label={"Meta descripition *"}
          charactersAllowed={500}
          name={"metaDescription"}
          register={register('metaDescription', {
            required: 'Meta descripition is required',
            maxLength: {
              value: 500,
              message: 'Meta descripition must be less than 500 characters'
            }
          })}
          errors={errors}
        />
      </Box>

      <Box mt={3}>
        <BlogsInput
          name={"footerUrl"}
          type={"text"}
          label={"Footer banner image URL (Optional)"}
          register={register('footerUrl', { required: '' })}
          errors={errors}
        />
      </Box>

      <Box mt={3}>
        <BlogsQuill
          name="blogContent"
          errors={errors}
          control={control}
        />
      </Box>
      {blog_id == null &&
        <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{
              marginTop: 3,
              boxShadow: "none",
            }}
            onClick={handleSubmit((value) => onSubmit(value, "draft"))}
          >
            Save as draft
          </Button>
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{
              marginTop: 3,
              boxShadow: "none",
              backgroundColor:
                palette.secondary.main,
            }}
            onClick={handleSubmit((value) => onSubmit(value, "published"))}
          >
            Publish
          </Button>
        </Box>
      }

      {blog_id !== null &&
        <Box display={"flex"} justifyContent={"flex-end"} gap={2}>

          <Button variant="contained" color="error"
            sx={{
              marginTop: 3,
            }}
            onClick={() => { setPopupMessage(popUpMessages["delete"]), setIsPopUpOpen(true) }}
          >
            Delete
          </Button>

          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{
              marginTop: 3,
              boxShadow: "none",
            }}
            onClick={handleSubmit((value) => onSubmit(value, singleBlogData?.status == "archive" ? "unarchive" : "archive"))}
          >
            {singleBlogData?.status == "archive" ? "Unarchive" : "Archive"}
          </Button>
        </Box>
      }


      <ModalComponent openModal={isPopupOpen} handleCloseModal={() => setIsPopUpOpen(false)} handleBtnClick={() => handleApiCall(popupMessage?.status)} cancelBtnText={"Cancel"} btnText={popupMessage?.buttonText}>
        <div>
          <p style={{ fontSize: "20px", marginBottom: "10px" }}><b>{popupMessage?.popupMessage}</b></p>
          <span style={{ fontSize: "16px" }}>{popupMessage?.subText}</span>
        </div>
      </ModalComponent>
    </div>
  );
};

export default CreateBlogs;