import React from 'react'
import BlogManagement from './BlogManagement'
import {
	Box,
	Button,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";

const BlogsList = () => {
   return (
		<>
			<Box>
				<Helmet>
					<title> BlogList | BeyondCarts CMS </title>
				</Helmet>
				<Box
					sx={{
						paddingBottom: 2,
						borderBottom: "1px solid #c4c4c4",
					}}
				>
					<Box
						sx={{
							display: "flex",
							marginTop: "20px",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Stack>
							<Typography variant="h4" sx={{ color: "#000" }}>
								Blog Management
							</Typography>
						</Stack>
						<AccountPopover />
					</Box>
				</Box>
				<BlogManagement />
			</Box>
		</>
	);
};

export default BlogsList;