import { Box } from '@mui/material';
import React, { useState } from 'react';
import { retrieveLogoUrl, uploadBrandedPortalImages, uploadToS3 } from 'src/features/config/configApi';
import { getUserInfo } from 'src/features/user/userState';
import { useAppSelector } from 'src/hooks/hooks';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const BlogsImageUploader = ({ setValue, value, handleImageDelete, required }) => {
    const [image, setImage] = useState(null);
    const [dragging, setDragging] = useState(false);
    const userInfo = useAppSelector(getUserInfo);


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Step 1: Set blob URL for immediate preview
            const fileURL = URL.createObjectURL(file);
            // setPreview(fileURL);

            // Define file upload details
            const fileType = file.type;
            const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/svg+xml"];

            if (!validImageTypes.includes(fileType)) {
                alert("File format must be JPG, PNG, or SVG.");
                URL.revokeObjectURL(fileURL); // Cleanup blob URL
                return;
            }

            // Prepare upload data
            const fileExtension = file.name.split(".").pop();
            const fileBody = {
                content_type: fileType,
                file_extension: fileExtension,
                upload_task: "marketing_blogs",
                file_name: file.name.split(".")[0]
            };
            try {
                const uploadResponse = await uploadBrandedPortalImages(fileBody, userInfo);
                if (uploadResponse.statusCode === 200) {
                    const headers = {
                        "Content-Type": fileType,
                        "Content-Length": file.size.toString(),
                        "x-amz-acl": "public-read",
                    };
                    await uploadToS3(uploadResponse.data.pre_signed_url, file, headers);

                    // Step 2: Update preview with the final URL after successful upload
                    const finalURL = `${retrieveLogoUrl}/${uploadResponse.data.file_path}`;
                    setValue({ fileName: file.name, url: finalURL, path: uploadResponse.data.file_path })
                } else {
                    throw new Error("Failed to get pre-signed URL");
                }
            } catch (error) {
                console.error(error.message, "kjwkejwkjekjwk");
                alert("Failed to upload image.");
            } finally {
                URL.revokeObjectURL(fileURL); // Cleanup blob URL
            }
        }
    };


    // Function to handle image drop
    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const file = e.dataTransfer.files[0]; // Get the dropped file
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result); // Set image preview
            };
            reader.readAsDataURL(file); // Convert the file to base64 format
        } else {
            alert('Please drop a valid image file.');
        }
    };

    // Function to handle drag over (required to allow dropping)
    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    // Function to handle drag leave
    const handleDragLeave = () => {
        setDragging(false);
    };
    console.log(image, 'imagesamhsgja')
    return (
        <>
            <div
                style={{
                    height: "200px",
                    width: "100%",
                    margin: "20px 0px",
                    position: "relative",
                    top: 0,
                    left: 0,
                }}
            >
                <img src={"/assets/dropzoneImage.png"} width={"100%"} height={"100%"} />
                {value == null ?

                    <div
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        style={{
                            top: 0,
                            left: 0,
                            color: "black",
                            padding: '20px',
                            width: '100%',
                            height: '100%',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: 'absolute',
                        }}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            // {...register}
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                opacity: 0,
                                cursor: "pointer"

                            }}
                        />
                        {image ? (
                            <img
                                src={image}
                                alt="Dropped Image"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    left: 0,
                                }}
                            />
                        ) : (
                            <>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-around"} flexDirection={"column"}>



                                    <>
                                        <img src='/assets/imageUpload.svg' style={{ marginBottom: "10px" }} />
                                        <p><span style={{ textDecoration: "underline", color: "#143F67" }}>Click to upload</span>  or drag and drop</p>
                                    </>

                                    <p style={{ marginTop: "10px", fontSize: "12px", fontWeight: 400, color: "#AAAAAA" }}>Max file size : 15MB &nbsp; Support format : JPF, JPEG, PNG</p>
                                </Box>
                            </>
                        )}
                    </div> :
                    <div style={{
                        top: 0,
                        left: 0,
                        color: "black",
                        padding: '20px',
                        width: '100%',
                        height: '100%',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: 'absolute',
                    }}>

                        <Box display={"flex"} flexDirection={"column"}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                            <p>
                                {value?.fileName}
                            </p>
                            <span onClick={(e) => { e.stopPropagation(); handleImageDelete() }}><HighlightOffIcon sx={{ fill: "#E50909", color: "#E50909", cursor: "pointer" }} /></span>
                            </Box>
                            <p style={{ marginTop: "10px", fontSize: "12px", fontWeight: 400, color: "#AAAAAA" }}>Max file size : 15MB &nbsp; Support format : JPF, JPEG, PNG</p>
                        
                        </Box>
                    </div>
                }
                {required &&
                    <span className={"text-error"}>{"Required"}</span>
                }
            </div>


        </>

    );
};

export default BlogsImageUploader;
