import { Box } from '@mui/material'
import React from 'react'

const BlogsInput = ({ label, type, register, cols = 10, rows = 2, charactersAllowed, errors, name }) => {
    console.log(name, errors, "slakskalkl1")
    return (
        <div>
            <>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <label className={"formLabel"}>{label}</label>
                    {charactersAllowed &&
                        <span className='spanText'>Maximum {charactersAllowed} characters</span>
                    }
                </Box>
                {type == "text" &&

                    <input
                        className={"inputStyle"}
                        type={type}
                        {...register}
                    />
                }
                {type == "textArea" &&
                    <textarea className={"textAreaStyle"} {...register} cols={cols} rows={rows} />}
            </>
            <span className={"text-error"}>{errors?.[name] && errors?.[name]?.message}</span>
        </div>
    )
}

export default BlogsInput